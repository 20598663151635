var PinUtils = {};
var ScrollMagic = ScrollMagic || {};

(function ($, ScrollMagic) {
  Drupal.behaviors.blog_article_formatter_v1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $thismodule = $('.js-blog-article', context);

      // Load Pinterest api script.
      loadScript('//assets.pinterest.com/js/pinit.js', function () {
        PinUtils = PinUtils || {};
      });

      $thismodule.each(function () {
        var $module = $(this);
        var $moduleSection = '.blog-article__section';
        var $articleImgs = $module.find('.blog-article__section .basic-img__wrapper img');
        var $heroImg = $articleImgs.first();
        var $mobileSocial = $module.find('.js-blog-article__social-share--mob');
        var $pinterestContainer = $module.find('.js-blog-article__social--pinterest');
        var $articleHeader = $module.closest('body').find('header.page-header');
        var $endTarget = $module.find('.social-pin-end');
        var $socialButtons = $module.find('.js-blog-article__social-share--desktop');
        var $shareContainer = $module.find('.js-blog-article__social-container');
        var $firstYouTubeVid = $module.find('.js-ytPlayer__icon').first();
        var $firstVidContentVid = $module.find('.video_content_cta').first();
        var $facebookElem = $module.find('.js-blog-article__social-share .facebook a');
        var $pinterestElem = $module.find('.js-blog-article__social-share .pinterest a');
        var url = window.location.href.split('#')[0];
        var baseURL = url.split('/')[2];
        var title = document.title;
        var facebook_url = `https://www.facebook.com/sharer.php?u=${encodeURI(url)}&quote=${title}`;
        var fbTop = window.clientWidth / 2 - 275;
        var fbLeft = window.clientHeight / 2 - 225;
        var socialDesktop = '.js-blog-article__social-share--desktop';
        var socialPin = new ScrollMagic.Controller();
        var socialPinScene, theOffset, duration, endY;
        var ieOffset = 390;
        var urlHash = window.location.href.split('#')[1];
        var videoAutoplay = 'video_autoplay';
        var youTubeExists = $firstYouTubeVid.length > 0;
        var vidContentExists = $firstVidContentVid.length > 0;
        var firstYouTubeIndex = $firstYouTubeVid.closest($moduleSection).index();
        var firstVidContentIndex = $firstYouTubeVid.closest($moduleSection).index();
        var youTubeExistsFirst = firstYouTubeIndex < firstVidContentIndex;
        var articleHeaderHeight = $articleHeader.outerHeight(true);
        var socialDesktopHeight = $(socialDesktop).outerHeight(true);

        // Attach Pinterest to all images.
        $articleImgs.each(function () {
          $(this).closest($moduleSection).addClass('blog-article__pinterest');
          $pinterestContainer.clone(true).insertAfter($(this));
        });

        // Handle Pinterest click.
        $module.on('click', '.js-blog-article__social--pinterest a.pinterest', function (e) {
          e.preventDefault();
          var pinterestImage = $(this).parent().siblings('img').attr('src');

          // LEGACY CODE //
          if (PinUtils) {
            PinUtils.pinOne({
              media: 'https://' + baseURL + '/' + pinterestImage,
              url: url,
              description: title
            });
          }
          // END LEGACY CODE //
        });

        // Apply the mobile social icons.
        $heroImg.closest($moduleSection).addClass('blog-article__social--attach');
        $mobileSocial.detach().insertAfter($heroImg.siblings());

        // Facebook Icon.
        $facebookElem.attr('href', facebook_url).on('click', function (e) {
          e.preventDefault();
          window.open(
            $(this).attr('href'),
            '_blank',
            `height=450, width=550, top=${fbTop}, left=${fbLeft}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
          );
        });

        // Pinterest Icon.
        $pinterestElem.on('click', function (e) {
          e.preventDefault();
          var pinterestImage = $articleImgs.first().attr('src');

          if (PinUtils) {
            PinUtils.pinOne({
              media: 'https://' + baseURL + '/' + pinterestImage,
              url: url,
              description: title
            });
          }
        });

        // Fixed Pin.
        function getDurationVal() {
          var calcOffset, theOffsetVal, initialPosition;

          if ($shareContainer.length === 0 || $endTarget.length === 0) {
            return 0;
          }

          // Find the 'starting point' of the pin.
          initialPosition = $shareContainer.offset().top - $socialButtons.outerHeight(true);
          calcOffset = articleHeaderHeight + socialDesktopHeight * 0.4;
          theOffsetVal = $endTarget.offset().top - (calcOffset + initialPosition);

          if ($('body').hasClass('ie')) {
            endY = theOffsetVal - ieOffset;
          } else {
            endY = theOffsetVal;
          }

          return endY;
        }

        function getOffsetVal() {
          var offsetVal = articleHeaderHeight - socialDesktopHeight * 1.4;

          return offsetVal;
        }
        // END LEGACY CODE //

        // Video Autoplay.
        // Playback options.
        function youTubePlayback() {
          $firstYouTubeVid[0].click();
        }

        function vidContentPlayback() {
          var $videoContentPlayer = $firstVidContentVid.parent().siblings('.video_content_player');

          $videoContentPlayer.find('video').attr('muted', true);
          $videoContentPlayer.find('.el_vcv1_vol_icon button').trigger('click');
          $firstVidContentVid.trigger('click');
        }

        // Playcback functionality.
        function playback(check) {
          if (check) {
            youTubePlayback();
          } else {
            vidContentPlayback();
          }
        }

        // Check if there's both a YouTube video & Video Content video.
        function videoChecks() {
          if (youTubeExists && vidContentExists) {
            playback(youTubeExistsFirst);
          } else {
            playback(youTubeExists);
          }
        }

        // Put in window.load to calculate correct offset vals across browsers.
        $(window).on('load', function () {
          // LEGACY CODE //
          socialPinScene = new ScrollMagic.Scene({
            triggerElement: socialDesktop
          })
            .setPin(socialDesktop)
            .setClassToggle(socialDesktop, 'is-fixed')
            .triggerHook(0)
            .addTo(socialPin);

          theOffset = getOffsetVal();
          duration = getDurationVal();
          socialPinScene.offset(theOffset);
          socialPinScene.duration(duration);
          window.scene = socialPinScene;
          // END LEGACY CODE //

          // Video Autoplay.
          if (urlHash === videoAutoplay) {
            videoChecks();
          }
        });
      });

      function loadScript(url, callback) {
        var script = document.createElement('script');

        script.type = 'text/javascript';
        if (script.readyState) {
          script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {
          script.onload = function () {
            callback();
          };
        }
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }
  };
})(jQuery, ScrollMagic);
